/* eslint-disable max-len */
import React from 'react';
/**
 * Renders Component.
 * @return {ChervonLeft} Icon Component.
 */
export default function ChervonLeft() {
  return (
    <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7 13L1 7L7 1" stroke="#111A1C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

  );
}
