/* eslint-disable max-len */
import React from 'react';
/**
 * Renders Component.
 * @return {Clock} Icon Component.
 */
export default function Clock() {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.77669 0.00334685C3.54479 -0.0163648 3.30583 0.050806 3.11385 0.209843L0.32386 2.5198C-0.0610092 2.83877 -0.109317 3.40427 0.213682 3.78327C0.536681 4.16227 1.11008 4.20985 1.49494 3.89177L4.28493 1.58182C4.66889 1.26285 4.71902 0.698195 4.39511 0.320091C4.23361 0.13059 4.00859 0.0230585 3.77669 0.00334685ZM14.2223 0.00334685C13.9903 0.0230585 13.7654 0.13059 13.6039 0.320091C13.2809 0.699091 13.3309 1.26285 13.7158 1.58182L16.504 3.89177C16.8889 4.20985 17.4631 4.16227 17.7871 3.78327C18.1101 3.40427 18.06 2.83877 17.6751 2.5198L14.8869 0.209843C14.6945 0.050806 14.4543 -0.0163648 14.2223 0.00334685ZM8.99948 1.87231C4.47659 1.87231 0.810775 5.48223 0.810775 9.93616C0.810775 14.3901 4.47659 18 8.99948 18C13.5224 18 17.1882 14.3901 17.1882 9.93616C17.1882 5.48223 13.5224 1.87231 8.99948 1.87231ZM8.99948 5.45624C9.50172 5.45624 9.90934 5.85764 9.90934 6.35222V10.2791L12.232 11.4219C12.6814 11.6432 12.8636 12.1815 12.6389 12.6241C12.4142 13.0667 11.8675 13.2462 11.4181 13.0248L8.59253 11.6336C8.285 11.4822 8.08963 11.1717 8.08963 10.8321V6.35222C8.08963 5.85764 8.49724 5.45624 8.99948 5.45624Z" fill="#1E3653"/>
    </svg>

  );
}
