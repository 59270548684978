/* eslint-disable max-len */
import React from 'react';
/**
 * Renders Component.
 * @return {Digestion} Icon Component.
 */
export default function Digestion() {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M9.75293 11.6615C9.75293 10.0543 11.0558 8.75146 12.6629 8.75146H21.3601C21.7743 8.75146 22.1101 9.08725 22.1101 9.50146C22.1101 9.91568 21.7743 10.2515 21.3601 10.2515H12.6629C11.8842 10.2515 11.2529 10.8827 11.2529 11.6615C11.2529 12.4402 11.8842 13.0715 12.6629 13.0715H27.0729C28.8292 13.0715 30.2529 14.4952 30.2529 16.2515C30.2529 18.0077 28.8292 19.4315 27.0729 19.4315H12.6629C11.8842 19.4315 11.2529 20.0627 11.2529 20.8415C11.2529 21.6202 11.8842 22.2515 12.6629 22.2515H29.5029C29.9171 22.2515 30.2529 22.5872 30.2529 23.0015C30.2529 23.4157 29.9171 23.7515 29.5029 23.7515H12.6629C11.0558 23.7515 9.75293 22.4486 9.75293 20.8415C9.75293 19.2343 11.0558 17.9315 12.6629 17.9315H27.0729C28.0008 17.9315 28.7529 17.1793 28.7529 16.2515C28.7529 15.3236 28.0008 14.5715 27.0729 14.5715H12.6629C11.0558 14.5715 9.75293 13.2686 9.75293 11.6615Z" fill="#278C71"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M7.24902 9C7.24902 6.37665 9.37567 4.25 11.999 4.25H27.999C30.6224 4.25 32.749 6.37665 32.749 9V24.4872C32.749 24.9014 32.4132 25.2372 31.999 25.2372C31.5848 25.2372 31.249 24.9014 31.249 24.4872V9C31.249 7.20507 29.7939 5.75 27.999 5.75H11.999C10.2041 5.75 8.74902 7.20507 8.74902 9V23.8205C8.74902 25.6154 10.2041 27.0705 11.999 27.0705H17.4822C20.1055 27.0705 22.2322 29.1972 22.2322 31.8205V35C22.2322 35.4142 21.8964 35.75 21.4822 35.75C21.068 35.75 20.7322 35.4142 20.7322 35V31.8205C20.7322 30.0256 19.2771 28.5705 17.4822 28.5705H11.999C9.37567 28.5705 7.24902 26.4439 7.24902 23.8205V9Z" fill="#278C71"/>
    </svg>

  );
}
