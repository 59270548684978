/* eslint-disable max-len */
import React from 'react';
/**
 * Renders Component.
 * @return {Fertility} Icon Component.
 */
export default function Fertility() {
  return (
    <svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M20.621 6.50049C13.1652 6.50049 7.12109 12.5446 7.12109 20.0004C7.12109 27.4562 13.1652 33.5004 20.621 33.5004C28.0768 33.5004 34.121 27.4562 34.121 20.0004C34.121 12.5446 28.0768 6.50049 20.621 6.50049ZM5.62109 20.0004C5.62109 11.7162 12.3368 5.00049 20.621 5.00049C28.9053 5.00049 35.621 11.7162 35.621 20.0004C35.621 28.2847 28.9053 35.0004 20.621 35.0004C12.3368 35.0004 5.62109 28.2847 5.62109 20.0004ZM16.0757 14.5542C14.4957 14.5542 13.1875 15.8674 13.1875 17.5216C13.1875 19.1757 14.4957 20.4889 16.0757 20.4889C17.6558 20.4889 18.964 19.1757 18.964 17.5216C18.964 15.8674 17.6558 14.5542 16.0757 14.5542ZM11.6875 17.5216C11.6875 15.0696 13.637 13.0542 16.0757 13.0542C18.0653 13.0542 19.7293 14.3956 20.2748 16.2211L20.9249 16.7737C21.6238 17.3676 22.667 17.3033 23.2876 16.628C23.9649 15.8911 25.0121 15.6212 25.9612 15.9389L26.3599 16.0724C27.9069 16.5903 28.9496 18.0391 28.9496 19.6705V19.9855C28.9496 21.6472 28.2768 23.2381 27.0846 24.3957L25.2439 26.183C23.584 27.7946 20.9173 27.7043 19.3704 25.984L16.1207 22.3702C16.0202 22.2585 15.9595 22.1249 15.9376 21.9868C13.5638 21.9124 11.6875 19.9271 11.6875 17.5216ZM17.56 21.7275L20.4857 24.981C21.4637 26.0686 23.1496 26.1257 24.199 25.1068L26.0397 23.3195C26.941 22.4444 27.4496 21.2417 27.4496 19.9855V19.6705C27.4496 18.684 26.8191 17.808 25.8837 17.4948L25.4849 17.3613C25.097 17.2314 24.6689 17.3418 24.392 17.643C23.3542 18.7723 21.6872 18.992 20.4075 18.2396C20.1509 19.8521 19.0443 21.185 17.56 21.7275Z" fill="#278C71"/>
    </svg>
  );
}
