/* eslint-disable max-len */
import React from 'react';
/**
 * Renders Component.
 * @return {HeaderLogo} Icon Component.
 */
export default function HeaderLogo() {
  return (
    <svg width="82" height="24" viewBox="0 0 82 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.7054 16.0532C12.2725 16.0532 13.0571 16.4831 13.0571 17.3147C13.0571 18.1463 11.8132 19.7571 10.055 21.2346C9.07907 22.0378 7.98596 22.6891 6.81309 23.1664C5.62532 23.7075 4.33528 23.9917 3.02849 24C0.995947 24 0 23.2753 0 22.0945C0 20.9137 0.784563 19.8923 2.37808 18.9537C1.24392 18.7923 0.674805 18.3078 0.674805 17.5307C0.674805 16.5376 1.9736 15.3285 3.83949 14.4686C1.91873 13.8247 0.567082 12.4299 0.567082 10.3085C0.558565 9.64683 0.708566 8.99265 1.00472 8.39988C1.30086 7.80711 1.73467 7.29275 2.27036 6.89924C3.334 6.04231 4.65893 5.56906 6.02854 5.55695H13.0286V5.61144L10.4879 6.95374C10.9427 7.28727 11.3096 7.72503 11.5573 8.22972C11.8051 8.73441 11.9263 9.29108 11.9107 9.85231C11.9342 10.5652 11.7753 11.2723 11.449 11.9076C11.1227 12.543 10.6397 13.0859 10.0449 13.4856C8.84864 14.3612 7.39651 14.8235 5.91064 14.8017C5.23381 14.8017 4.74802 15.1246 4.74802 15.5526C4.74802 15.9018 5.15454 16.0633 5.96756 16.0633L10.7054 16.0532ZM8.13019 20.831C9.18507 20.2678 9.72574 19.7854 9.72574 19.3554C9.72574 19.1415 9.45542 19.0325 8.88833 19.0325H3.67688C3.05493 19.4362 2.72972 19.9468 2.72972 20.51C2.72972 21.2892 3.43297 21.6646 4.8395 21.6646C5.98982 21.6682 7.12215 21.3813 8.13019 20.831ZM4.38013 9.48697C4.35716 10.5007 4.60918 11.5018 5.10982 12.3855C5.59561 13.1929 6.13625 13.5966 6.75823 13.5966C7.62205 13.5966 8.1627 12.3613 8.1627 10.6153C8.1627 9.51455 7.91949 8.60219 7.43303 7.87821C6.94725 7.15356 6.4066 6.77611 5.80698 6.77611C5.05494 6.76401 4.38013 7.75911 4.38013 9.48697Z" fill="black"/>
      <path d="M19.9494 10.7384C20.0307 8.29603 19.4088 6.73773 18.1385 6.73773H18.0897V15.7039C18.0897 17.4217 18.2523 18.2815 19.5125 18.9335V18.9739H12.8051V18.9194C14.0754 18.2755 14.238 17.4156 14.238 15.6898V8.82687C14.238 7.10713 14.0754 6.24924 12.8051 5.59728V5.54279H19.0856C21.356 5.54279 22.8418 6.37439 23.1934 8.95202L19.9494 10.7384Z" fill="black"/>
      <path d="M22.6527 18.9739V18.9194C23.9231 18.2755 24.0755 17.4157 24.0755 15.6898V8.8269C24.0755 7.10715 23.913 6.2493 22.6527 5.59731V5.54281H27.9232V15.7039C27.9232 17.4217 28.0856 18.2816 29.3561 18.9336V18.9739H22.6527ZM25.9048 0C27.094 0 28.0675 0.938604 28.0675 2.04071C28.0434 2.59421 27.8048 3.11706 27.4018 3.50023C26.9989 3.88338 26.4627 4.09726 25.9048 4.09726C25.3471 4.09726 24.8106 3.88338 24.4077 3.50023C24.0048 3.11706 23.7664 2.59421 23.7422 2.04071C23.7341 0.938604 24.7077 0 25.8966 0H25.9048Z" fill="black"/>
      <path d="M37.6287 18.9739C34.2504 19.1617 32.3297 18.8932 31.4923 17.8193C31.0858 17.2824 30.8175 16.8101 30.7361 16.3418C30.6437 15.6055 30.6069 14.8633 30.6265 14.1214V7.00623H29.0857V5.66391C31.1183 5.31473 33.0331 3.59697 34.411 1.04761H34.4659V5.55693H36.8441V7.00623H34.4659V14.4969C34.4659 15.5707 34.6551 16.4024 35.0616 16.9655C35.4681 17.5287 36.3033 18.2009 37.6287 18.9255V18.9739Z" fill="black"/>
      <path d="M52.4152 5.55698H56.2527V5.61147C55.9879 5.89788 55.7691 6.22336 55.6045 6.57631C55.4887 6.81067 55.3893 7.0528 55.3076 7.30096C55.2264 7.49068 55.1715 7.62392 55.145 7.70464C55.0638 7.89237 54.8483 8.48379 54.7671 8.69775L50.9012 18.9739H50.0882L46.7487 10.7647L43.6021 18.9739H42.7891L38.6022 8.833C37.8441 7.03248 37.2768 5.95864 36.8725 5.60339V5.54889H43.279V5.60339C41.8461 6.11206 41.71 7.05268 42.3582 8.42929L44.3096 13.3948L45.9356 8.7785C45.206 6.98002 44.6387 5.90616 44.2322 5.61147V5.55698H50.6308V5.61147C49.208 6.12012 49.09 7.06075 49.712 8.43738L51.5413 13.3504L53.2529 8.56454C53.9011 7.087 53.7934 6.06764 52.4152 5.61147V5.55698Z" fill="black"/>
      <path d="M67.6066 14.4969L67.6616 14.5231C67.2737 15.7684 66.5875 16.9016 65.6615 17.8254C65.2249 18.2762 64.6994 18.6328 64.1178 18.8728C63.5363 19.1127 62.911 19.231 62.2814 19.2202C60.7406 19.2202 59.3625 18.6308 58.1735 17.4217C56.9845 16.2126 56.3889 14.5231 56.3889 12.2947C56.3889 10.1207 57.0373 8.42924 58.3077 7.19393C59.5781 5.95859 61.0375 5.3147 62.633 5.3147C64.0923 5.3147 65.3099 5.90611 66.3097 7.0607C67.3098 8.21528 67.8505 9.85229 67.877 11.9737H60.1533C60.5029 14.8723 62.0984 16.2126 64.0151 16.2126C65.2285 16.2146 66.4177 15.6514 67.6066 14.4969ZM60.0862 10.5224H64.2487C64.0049 8.13455 63.1675 6.52378 62.1125 6.52378C61.1471 6.52378 60.1736 8.0538 60.0924 10.5224H60.0862Z" fill="black"/>
      <path d="M67.9582 18.9739V18.9194C69.2286 18.2755 69.381 17.4157 69.381 15.6899V3.46378C69.381 1.74401 69.2184 0.886146 67.9582 0.23417V0.187744H73.2307V15.704C73.2307 17.4217 73.3912 18.2816 74.6535 18.9336V18.9739H67.9582Z" fill="black"/>
      <path d="M74.5274 18.9739V18.9194C75.7978 18.2755 75.9605 17.4157 75.9605 15.6899V3.46378C75.9605 1.74401 75.7978 0.886146 74.5274 0.23417V0.187744H79.7977V15.704C79.7977 17.4217 79.9604 18.2816 81.2205 18.9336V18.9739H74.5274Z" fill="black"/>
    </svg>


  );
}
