/* eslint-disable max-len */
import React from 'react';
/**
 * Renders Component.
 * @return {Supplement} Icon Component.
 */
export default function Testing() {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.341522 3.20039C0.341522 1.75793 1.51086 0.588593 2.95332 0.588593H21.2359C22.6784 0.588593 23.8477 1.75793 23.8477 3.20039V21.483C23.8477 22.9254 22.6784 24.0948 21.2359 24.0948H2.95332C1.51086 24.0948 0.341522 22.9254 0.341522 21.483V3.20039ZM21.2359 3.20039H2.95332V21.483H21.2359V3.20039ZM12.0946 5.81219C12.8158 5.81219 13.4005 6.39686 13.4005 7.11808V17.5653C13.4005 18.2865 12.8158 18.8712 12.0946 18.8712C11.3734 18.8712 10.7887 18.2865 10.7887 17.5653V7.11808C10.7887 6.39686 11.3734 5.81219 12.0946 5.81219ZM17.3182 8.42398C18.0394 8.42398 18.6241 9.00865 18.6241 9.72988V17.5653C18.6241 18.2865 18.0394 18.8712 17.3182 18.8712C16.597 18.8712 16.0123 18.2865 16.0123 17.5653V9.72988C16.0123 9.00865 16.597 8.42398 17.3182 8.42398ZM6.87101 11.0358C7.59224 11.0358 8.17691 11.6205 8.17691 12.3417V17.5653C8.17691 18.2865 7.59224 18.8712 6.87101 18.8712C6.14979 18.8712 5.56512 18.2865 5.56512 17.5653V12.3417C5.56512 11.6205 6.14979 11.0358 6.87101 11.0358Z" fill="#355F92"/>
    </svg>
  );
}
