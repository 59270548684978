/* eslint-disable max-len */
import React from 'react';
/**
 * Renders Component.
 * @return {NotificationBellIcon} Icon Component.
 */
export default function NotificationBellIcon() {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.1461 3.82332C10.4433 3.09135 11.1614 2.5752 12 2.5752C12.8386 2.5752 13.5567 3.09135 13.8539 3.82332C16.8202 4.63591 19 7.35099 19 10.5752V15.2724L20.8321 18.0205C21.0366 18.3274 21.0557 18.7219 20.8817 19.0471C20.7077 19.3722 20.3688 19.5752 20 19.5752H15.4646C15.2219 21.2713 13.7632 22.5752 12 22.5752C10.2368 22.5752 8.77806 21.2713 8.53545 19.5752H4C3.63121 19.5752 3.29235 19.3722 3.11833 19.0471C2.94431 18.7219 2.96338 18.3274 3.16795 18.0205L5 15.2724V10.5752C5 7.35099 7.17983 4.63591 10.1461 3.82332ZM10.5854 19.5752C10.7913 20.1578 11.3469 20.5752 12 20.5752C12.6531 20.5752 13.2087 20.1578 13.4146 19.5752H10.5854ZM12 5.5752C9.23858 5.5752 7 7.81377 7 10.5752V15.5752C7 15.7726 6.94156 15.9656 6.83205 16.1299L5.86852 17.5752H18.1315L17.168 16.1299C17.0584 15.9656 17 15.7726 17 15.5752V10.5752C17 7.81377 14.7614 5.5752 12 5.5752Z" fill="black"/>
    </svg>


  );
}
