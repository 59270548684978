/* eslint-disable max-len */
import React from 'react';
/**
 * Renders Component.
 * @return {Visa} Icon Component.
 */
export default function SubTest() {
  return (
    <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 2C0 0.895432 0.89543 0 2 0H10C10.2652 0 10.5196 0.105357 10.7071 0.292893L15.7071 5.29289C15.8946 5.48043 16 5.73478 16 6V18C16 19.1046 15.1046 20 14 20H2C0.895432 20 0 19.1046 0 18V2ZM13.5858 6L10 2.41421V6H13.5858ZM8 2L2 2V18H14V8H9C8.44772 8 8 7.55228 8 7V2ZM11.7071 10.2929C12.0976 10.6834 12.0976 11.3166 11.7071 11.7071L7.70711 15.7071C7.31658 16.0976 6.68342 16.0976 6.29289 15.7071L4.29289 13.7071C3.90237 13.3166 3.90237 12.6834 4.29289 12.2929C4.68342 11.9024 5.31658 11.9024 5.70711 12.2929L7 13.5858L10.2929 10.2929C10.6834 9.90237 11.3166 9.90237 11.7071 10.2929Z" fill="#6F7381"/>
    </svg>


  );
}
