/* eslint-disable max-len */
import React from 'react';
/**
 * Renders Component.
 * @return {CartBlue} Icon Component.
 */
export default function CartBlue() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.1421 2.00014L4.00913 14.1358C4.02271 14.236 4.05113 14.3314 4.0921 14.4199C4.21543 14.6862 4.45246 14.8891 4.74088 14.9662C4.82899 14.9899 4.92133 15.0017 5.01578 15.0001H16C16.4416 15.0001 16.8309 14.7105 16.9578 14.2875L19.9578 4.28749C20.0487 3.98471 19.991 3.65682 19.8023 3.40321C19.6136 3.1496 19.3161 3.00014 19 3.00014H4.31948L3.99058 0.862329C3.97826 0.772947 3.95413 0.687334 3.91981 0.607118C3.85751 0.461042 3.76213 0.334509 3.64429 0.235329C3.53497 0.143138 3.40561 0.0739612 3.26367 0.0352648C3.17434 0.0108006 3.0806 -0.00145301 2.9847 0.00013733H1C0.447715 0.00013733 0 0.447853 0 1.00014C0 1.55242 0.447715 2.00014 1 2.00014H2.1421ZM5.85794 13.0001L4.62717 5.00014H17.656L15.256 13.0001H5.85794Z" fill="#355F92"/>
      <path d="M8 18.0001C8 19.1047 7.10457 20.0001 6 20.0001C4.89543 20.0001 4 19.1047 4 18.0001C4 16.8956 4.89543 16.0001 6 16.0001C7.10457 16.0001 8 16.8956 8 18.0001Z" fill="#355F92"/>
      <path d="M17 18.0001C17 19.1047 16.1046 20.0001 15 20.0001C13.8954 20.0001 13 19.1047 13 18.0001C13 16.8956 13.8954 16.0001 15 16.0001C16.1046 16.0001 17 16.8956 17 18.0001Z" fill="#355F92"/>
    </svg>

  );
}
