/* eslint-disable max-len */
import React from 'react';
/**
 * Renders Component.
 * @return {Test} Icon Component.
 */
export default function TestBlack() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M22.547 7.82796L16.922 2.20296C16.8175 2.09808 16.6933 2.01486 16.5565 1.95808C16.4198 1.9013 16.2732 1.87207 16.1251 1.87207C15.977 1.87207 15.8304 1.9013 15.6937 1.95808C15.5569 2.01486 15.4328 2.09808 15.3282 2.20296L3.14074 14.3905C2.70204 14.8121 2.35184 15.3169 2.11065 15.8755C1.86946 16.4341 1.74211 17.0352 1.73607 17.6437C1.73002 18.2521 1.8454 18.8556 2.07545 19.4189C2.30549 19.9821 2.64559 20.4939 3.07583 20.9241C3.50606 21.3544 4.0178 21.6945 4.58108 21.9245C5.14436 22.1545 5.74787 22.2699 6.35629 22.2639C6.96471 22.2578 7.56581 22.1305 8.12441 21.8893C8.68301 21.6481 9.18788 21.2979 9.60949 20.8592L20.1095 10.3592L22.1064 9.69358C22.2874 9.63183 22.45 9.52568 22.5794 9.38483C22.7088 9.24398 22.8008 9.07293 22.847 8.88733C22.8884 8.69971 22.8827 8.50475 22.8304 8.31988C22.778 8.135 22.6806 7.96599 22.547 7.82796V7.82796ZM8.01574 19.2655C7.58062 19.7006 6.99047 19.945 6.37511 19.945C5.75976 19.945 5.16961 19.7006 4.73449 19.2655C4.29937 18.8303 4.05492 18.2402 4.05492 17.6248C4.05492 17.0095 4.29937 16.4193 4.73449 15.9842L7.10636 13.6123C7.35011 13.453 8.66261 12.7123 10.7439 13.753C11.4003 14.0859 12.1012 14.3227 12.8251 14.4561L8.01574 19.2655ZM19.1439 8.30608C18.9778 8.36122 18.827 8.45431 18.7032 8.57796L15.3939 11.8873C15.1501 12.0467 13.8376 12.7873 11.7564 11.7467C11.0999 11.4138 10.399 11.177 9.67511 11.0436L16.1251 4.59358L19.6689 8.13733L19.1439 8.30608Z" fill="#000000"/>
    </svg>


  );
}
