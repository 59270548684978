/* eslint-disable max-len */
import React from 'react';
/**
 * Renders Component.
 * @return {TaskResult} Icon Component.
 */
export default function TaskResult() {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M22.5475 8.24772L16.9225 2.55693C16.818 2.45082 16.6938 2.36663 16.557 2.30918C16.4203 2.25174 16.2737 2.22217 16.1256 2.22217C15.9775 2.22217 15.8309 2.25174 15.6942 2.30918C15.5574 2.36663 15.4332 2.45082 15.3287 2.55693L3.14122 14.887C2.70253 15.3135 2.35233 15.8243 2.11114 16.3894C1.86994 16.9546 1.7426 17.5627 1.73655 18.1782C1.73051 18.7938 1.84589 19.4043 2.07594 19.9742C2.30598 20.5441 2.64608 21.0618 3.07631 21.497C3.50655 21.9323 4.01829 22.2764 4.58157 22.5091C5.14485 22.7419 5.74836 22.8586 6.35678 22.8525C6.96519 22.8464 7.56629 22.7175 8.12489 22.4735C8.68349 22.2295 9.18837 21.8752 9.60997 21.4314L20.11 10.8086L22.1068 10.1352C22.2879 10.0727 22.4505 9.96529 22.5799 9.82279C22.7093 9.6803 22.8013 9.50725 22.8475 9.31948C22.8889 9.12967 22.8832 8.93242 22.8308 8.74539C22.7785 8.55835 22.6811 8.38736 22.5475 8.24772ZM8.01622 19.819C7.5811 20.2592 6.99095 20.5065 6.3756 20.5065C5.76025 20.5065 5.1701 20.2592 4.73498 19.819C4.29985 19.3788 4.05541 18.7817 4.05541 18.1592C4.05541 17.5366 4.29985 16.9396 4.73498 16.4994L7.10685 14.0997C7.3506 13.9385 8.6631 13.1892 10.7443 14.242C11.4008 14.5788 12.1017 14.8184 12.8256 14.9534L8.01622 19.819ZM19.1444 8.73143C18.9783 8.78721 18.8275 8.88139 18.7037 9.00649L15.3944 12.3546C15.1506 12.5158 13.8381 13.2651 11.7569 12.2123C11.1004 11.8755 10.3995 11.6359 9.6756 11.501L16.1256 4.97551L19.6693 8.56071L19.1444 8.73143Z" fill="#72C4AA"/>
    </svg>


  );
}
