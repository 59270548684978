/* eslint-disable max-len */
import React from 'react';
/**
 * Renders Component.
 * @return {Shipping} Icon Component.
 */
export default function Shipping() {
  return (
    <svg width="20" height="13" viewBox="0 0 20 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M1.47223 0.300781C0.714155 0.300781 0.0996094 0.915327 0.0996094 1.67341V10.0399C0.0996094 10.798 0.714155 11.4125 1.47223 11.4125H2.50415C2.69233 12.264 3.45138 12.9008 4.35937 12.9008C5.26737 12.9008 6.02642 12.264 6.2146 11.4125L11.5767 11.4125H14.5444C14.7326 12.264 15.4916 12.9008 16.3996 12.9008C17.3076 12.9008 18.0666 12.264 18.2548 11.4125H18.527C19.2851 11.4125 19.8996 10.798 19.8996 10.0399V7.06155C19.8996 6.73109 19.7804 6.41173 19.5639 6.1621L16.2856 2.38293C16.0248 2.08239 15.6466 1.90975 15.2487 1.90975H12.9275V1.67341C12.9275 0.915327 12.313 0.300781 11.5549 0.300781H1.47223ZM1.45045 1.67341C1.45045 1.66137 1.4602 1.65162 1.47223 1.65162H11.5549C11.5669 1.65162 11.5767 1.66137 11.5767 1.67341V6.42377H1.45045V1.67341ZM12.9277 7.77461V6.42377L12.9276 3.26059H14.6809C14.2724 3.4554 13.9901 3.8722 13.9901 4.35492V6.17839C13.9901 6.84761 14.5326 7.39012 15.2019 7.39012H18.5488V10.0399C18.5488 10.052 18.539 10.0617 18.527 10.0617H18.0516C17.7249 9.48818 17.1078 9.10078 16.3996 9.10078C15.6914 9.10078 15.0743 9.48818 14.7476 10.0617H12.9276L12.9277 7.77461ZM15.341 6.03928V4.49403H16.3286L17.6691 6.03928H15.341ZM1.45045 10.0399V7.77461H11.5767V10.0617H6.01137C5.68467 9.48817 5.06754 9.10078 4.35937 9.10078C3.65121 9.10078 3.03408 9.48817 2.70738 10.0617H1.47223C1.4602 10.0617 1.45045 10.0519 1.45045 10.0399Z" fill="#067356"/>
    </svg>
  );
}
