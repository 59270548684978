/* eslint-disable max-len */
import React from 'react';
/**
 * Renders Component.
 * @return {Heart} Icon Component.
 */
export default function Heart() {
  return (
    <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.4998 4.52765C9.24217 2.41689 5.77021 2.49347 3.60064 4.75736C1.35513 7.1005 1.35513 10.8995 3.60064 13.2426L10.1446 20.0711C10.8931 20.8521 12.1066 20.8521 12.8551 20.0711L19.399 13.2426C21.6446 10.8995 21.6446 7.1005 19.399 4.75736C17.2295 2.49347 13.7575 2.41689 11.4998 4.52765ZM10.3771 6.17157L10.8222 6.63604C11.1964 7.02656 11.8032 7.02656 12.1775 6.63604L12.6226 6.17157C14.1196 4.60948 16.5467 4.60948 18.0437 6.17157C19.5408 7.73367 19.5408 10.2663 18.0437 11.8284L11.4998 18.6569L4.95593 11.8284C3.45892 10.2663 3.45892 7.73367 4.95593 6.17157C6.45294 4.60948 8.88007 4.60948 10.3771 6.17157Z" fill="#D29B22"/>
    </svg>
  );
}
