/* eslint-disable max-len */
import React from 'react';
/**
 * Renders Component.
 * @return {File} Icon Component.
 */
export default function File() {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3 5.4375C3 4.33293 3.89543 3.4375 5 3.4375H19C20.1046 3.4375 21 4.33293 21 5.4375V19.4375C21 20.5421 20.1046 21.4375 19 21.4375H5C3.89543 21.4375 3 20.5421 3 19.4375V5.4375ZM19 5.4375H5V19.4375H19V5.4375ZM12 7.4375C12.5523 7.4375 13 7.88522 13 8.4375V16.4375C13 16.9898 12.5523 17.4375 12 17.4375C11.4477 17.4375 11 16.9898 11 16.4375V8.4375C11 7.88522 11.4477 7.4375 12 7.4375ZM16 9.4375C16.5523 9.4375 17 9.88522 17 10.4375V16.4375C17 16.9898 16.5523 17.4375 16 17.4375C15.4477 17.4375 15 16.9898 15 16.4375V10.4375C15 9.88522 15.4477 9.4375 16 9.4375ZM8 11.4375C8.55228 11.4375 9 11.8852 9 12.4375V16.4375C9 16.9898 8.55228 17.4375 8 17.4375C7.44772 17.4375 7 16.9898 7 16.4375V12.4375C7 11.8852 7.44772 11.4375 8 11.4375Z" fill="#D29B22"/>
    </svg>


  );
}
