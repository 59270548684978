/* eslint-disable max-len */
import React from 'react';
/**
 * Renders Component.
 * @return {AutoimmuneInflammation} Icon Component.
 */
export default function AutoimmuneInflammation() {
  return (
    <svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M14.7305 5C14.7305 4.58579 15.0663 4.25 15.4805 4.25C24.2511 4.25 31.3733 11.2948 31.3733 20C31.3733 28.7052 24.2511 35.75 15.4805 35.75C15.0663 35.75 14.7305 35.4142 14.7305 35C14.7305 34.5858 15.0663 34.25 15.4805 34.25C23.4361 34.25 29.8733 27.8634 29.8733 20C29.8733 12.1366 23.4361 5.75 15.4805 5.75C15.0663 5.75 14.7305 5.41421 14.7305 5Z" fill="#458AAF"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M14.7305 11.2261C14.7305 10.8119 15.0663 10.4761 15.4805 10.4761C20.7796 10.4761 25.0876 14.7332 25.0876 19.9997C25.0876 25.2661 20.7796 29.5232 15.4805 29.5232C15.0663 29.5232 14.7305 29.1875 14.7305 28.7732C14.7305 28.359 15.0663 28.0232 15.4805 28.0232C19.9646 28.0232 23.5876 24.4243 23.5876 19.9997C23.5876 15.575 19.9646 11.9761 15.4805 11.9761C15.0663 11.9761 14.7305 11.6403 14.7305 11.2261Z" fill="#458AAF"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M14.7305 7.83057C14.7305 7.41635 15.0663 7.08057 15.4805 7.08057C22.6732 7.08057 28.5162 12.8583 28.5162 20.0004C28.5162 27.1425 22.6732 32.9202 15.4805 32.9202C15.0663 32.9202 14.7305 32.5844 14.7305 32.1702C14.7305 31.756 15.0663 31.4202 15.4805 31.4202C21.8582 31.4202 27.0162 26.3007 27.0162 20.0004C27.0162 13.7001 21.8582 8.58057 15.4805 8.58057C15.0663 8.58057 14.7305 8.24478 14.7305 7.83057Z" fill="#458AAF"/>
      <ellipse cx="16.0497" cy="19.9999" rx="5.42857" ry="5.37736" fill="#458AAF"/>
    </svg>

  );
}
