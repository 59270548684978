/* eslint-disable max-len */
import React from 'react';
/**
 * Renders Component.
 * @return {PlusIcon} Icon Component.
 */
export default function PlusIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.00008 2.66699C8.36827 2.66699 8.66675 2.96547 8.66675 3.33366V7.33366H12.6667C13.0349 7.33366 13.3334 7.63214 13.3334 8.00033C13.3334 8.36852 13.0349 8.667 12.6667 8.667H8.66675V12.667C8.66675 13.0352 8.36827 13.3337 8.00008 13.3337C7.63189 13.3337 7.33341 13.0352 7.33341 12.667V8.667H3.33341C2.96522 8.667 2.66675 8.36852 2.66675 8.00033C2.66675 7.63214 2.96522 7.33366 3.33341 7.33366H7.33341V3.33366C7.33341 2.96547 7.63189 2.66699 8.00008 2.66699Z" fill="#1E3653"/>
    </svg>


  );
}
