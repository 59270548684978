/* eslint-disable max-len */
import React from 'react';
/**
 * Renders Component.
 * @return {User} Icon Component.
 */
export default function Phone() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.83301 4H8.32295L9.77016 7.61803L7.44528 9.16795C7.16708 9.35342 6.99998 9.66565 6.99998 10C7.00252 10.0936 6.99998 10.001 6.99998 10.001L6.99998 10.002L6.99999 10.0043L7.00002 10.0093L7.00017 10.0217C7.00032 10.0309 7.00057 10.0421 7.00099 10.0552C7.00184 10.0814 7.00337 10.1153 7.0061 10.1564C7.01158 10.2385 7.02189 10.3494 7.04128 10.4852C7.08004 10.7565 7.15526 11.1281 7.3013 11.5662C7.59453 12.4459 8.17071 13.5849 9.29288 14.7071C10.415 15.8293 11.554 16.4054 12.4338 16.6987C12.8719 16.8447 13.2435 16.9199 13.5148 16.9587C13.6505 16.9781 13.7615 16.9884 13.8436 16.9939C13.8847 16.9966 13.9186 16.9981 13.9448 16.999C13.9579 16.9994 13.9691 16.9997 13.9783 16.9998L13.9906 17L13.9957 17L13.9979 17L13.999 17C13.999 17 14.1106 16.9939 14 17C14.3788 17 14.725 16.786 14.8944 16.4472L15.5641 15.1078L20 15.8471V20.167C17.8889 20.4723 12.1874 20.7732 7.70709 16.2929C3.22679 11.8126 3.5277 6.1111 3.83301 4ZM9.07363 10.4861L10.8796 9.28213C11.6665 8.75751 11.9784 7.75338 11.6271 6.87525L10.1799 3.25722C9.87618 2.4979 9.14076 2 8.32295 2H3.78076C2.87225 2 2.01707 2.63116 1.86792 3.6169C1.52861 5.8595 1.06677 12.481 6.29288 17.7071C11.519 22.9332 18.1405 22.4714 20.3831 22.1321C21.3688 21.9829 22 21.1277 22 20.2192V15.8471C22 14.8694 21.2932 14.0351 20.3288 13.8743L15.8929 13.135C15.0268 12.9907 14.1679 13.4281 13.7753 14.2134L13.4288 14.9064C13.3199 14.8796 13.1983 14.8453 13.0662 14.8013C12.4459 14.5946 11.5849 14.1707 10.7071 13.2929C9.82925 12.4151 9.40543 11.5541 9.19867 10.9338C9.14279 10.7662 9.10257 10.6154 9.07363 10.4861Z" fill="#6F7381"/>
    </svg>
  );
}
