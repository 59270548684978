/* eslint-disable max-len */
import React from 'react';
/**
 * Renders Component.
 * @return {TaskCheckIn} Icon Component.
 */
export default function TaskCheckIn() {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8 3.36326C8 2.80451 8.44772 2.35156 9 2.35156H15C15.5523 2.35156 16 2.80451 16 3.36326H18C19.1046 3.36326 20 4.26916 20 5.38665V20.5621C20 21.6796 19.1046 22.5855 18 22.5855H6C4.89543 22.5855 4 21.6796 4 20.5621V5.38665C4 4.26916 4.89543 3.36326 6 3.36326H8ZM8 5.38665H6V20.5621H18V5.38665H16V6.39835C16 6.95709 15.5523 7.41004 15 7.41004H9C8.44772 7.41004 8 6.95709 8 6.39835V5.38665ZM14 4.37495H10V5.38665H14V4.37495ZM15.7071 11.2473C16.0976 11.6424 16.0976 12.283 15.7071 12.6781L11.7071 16.7248C11.3166 17.1199 10.6834 17.1199 10.2929 16.7248L8.29289 14.7014C7.90237 14.3064 7.90237 13.6658 8.29289 13.2707C8.68342 12.8756 9.31658 12.8756 9.70711 13.2707L11 14.5787L14.2929 11.2473C14.6834 10.8522 15.3166 10.8522 15.7071 11.2473Z" fill="#F5B873"/>
    </svg>


  );
}
