/* eslint-disable max-len */
import React from 'react';
/**
 * Renders Component.
 * @return {Visa} Icon Component.
 */
export default function ComReg() {
  return (
    <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4 1C4 0.447715 4.44772 0 5 0H11C11.5523 0 12 0.447715 12 1H14C15.1046 1 16 1.89543 16 3V18C16 19.1046 15.1046 20 14 20H2C0.895431 20 0 19.1046 0 18V3C0 1.89543 0.895431 1 2 1H4ZM4 3H2V18H14V3H12V4C12 4.55228 11.5523 5 11 5H5C4.44772 5 4 4.55228 4 4V3ZM10 2H6V3H10V2ZM11.7071 8.79289C12.0976 9.18342 12.0976 9.81658 11.7071 10.2071L7.70711 14.2071C7.31658 14.5976 6.68342 14.5976 6.29289 14.2071L4.29289 12.2071C3.90237 11.8166 3.90237 11.1834 4.29289 10.7929C4.68342 10.4024 5.31658 10.4024 5.70711 10.7929L7 12.0858L10.2929 8.79289C10.6834 8.40237 11.3166 8.40237 11.7071 8.79289Z" fill="#1E3653"/>
    </svg>
  );
}
