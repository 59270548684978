/* eslint-disable max-len */
import React from 'react';
/**
 * Renders Component.
 * @return {Close} Icon Component.
 */
export default function CloseIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.22517 4.81196C6.03657 4.6298 5.78397 4.52901 5.52177 4.53129C5.25957 4.53357 5.00876 4.63874 4.82335 4.82414C4.63794 5.00955 4.53278 5.26036 4.5305 5.52256C4.52822 5.78476 4.62901 6.03736 4.81117 6.22596L10.5862 12.001L4.81017 17.776C4.71466 17.8682 4.63848 17.9786 4.58607 18.1006C4.53366 18.2226 4.50607 18.3538 4.50492 18.4866C4.50377 18.6193 4.52907 18.751 4.57935 18.8739C4.62963 18.9968 4.70388 19.1085 4.79778 19.2024C4.89167 19.2963 5.00332 19.3705 5.12622 19.4208C5.24911 19.4711 5.38079 19.4964 5.51357 19.4952C5.64635 19.4941 5.77757 19.4665 5.89958 19.4141C6.02158 19.3617 6.13192 19.2855 6.22417 19.19L12.0002 13.415L17.7752 19.19C17.9638 19.3721 18.2164 19.4729 18.4786 19.4706C18.7408 19.4684 18.9916 19.3632 19.177 19.1778C19.3624 18.9924 19.4676 18.7416 19.4698 18.4794C19.4721 18.2172 19.3713 17.9646 19.1892 17.776L13.4142 12.001L19.1892 6.22596C19.3713 6.03736 19.4721 5.78476 19.4698 5.52256C19.4676 5.26036 19.3624 5.00955 19.177 4.82414C18.9916 4.63874 18.7408 4.53357 18.4786 4.53129C18.2164 4.52901 17.9638 4.6298 17.7752 4.81196L12.0002 10.587L6.22517 4.81096V4.81196Z" fill="#68787A"/>
    </svg>
  );
}
