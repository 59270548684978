/* eslint-disable max-len */
import axios from 'axios';
import {clearLocalStorage, getLocalStorage} from '../core/local-storage-service';
import {formatPayload} from '@app/utils';

const token = getLocalStorage('gritwell_client_access_token');
const Http = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    // 'Authorization':  token
  },
  withCredentials: true,
  responseType: 'json',
});
Http.interceptors.request.use(
    async (config) => {
      if (config?.data && process.env.REACT_APP_ENCRYPT_DATA === 'TRUE'&&
        config?.headers&& config?.headers['content-type'] != 'multipart/form-data') {
        const encryptedData = await formatPayload(JSON.stringify(config.data));
        config.data = encryptedData;
      }
      if (token) {
        // config.headers.Authorization = token;
      } else {
        delete Http.defaults.headers.common.Authorization;
      }
      return config;
    },
    (error) => Promise.reject(error),
);

Http.interceptors.response.use(
    (response) => {
      const responseCode = response.data.status_code;
      const statusCode = responseCode ? responseCode : '';
      if (statusCode === 403) {
        clearLocalStorage();
        sessionStorage.clear();
        // window.location.reload();
      }
      if (statusCode === 451) {
        localStorage.setItem('terms_and_conditions', response.data.is_terms_and_conditions_accepted);
        window.location.replace(process.env.REACT_APP_HOMEPAGE +'home');
      }
      return response;
    },
    (error) => {
      if (error.response) {
        if (error.response.status === 403 || error.response.status === 401) {
          clearLocalStorage();
          sessionStorage.clear();
          window.location.replace(process.env.REACT_APP_HOMEPAGE +'sign-up');
        }
        if (error.response.status === 451 && window.location.pathname !== '/' && window.location.pathname !== '/care-sign-in' && window.location.pathname !== '/care-sign-up') {
          if (error?.response?.data) {
            localStorage.removeItem('policyUrls');
            const policyUrls = error.response?.data?.terms_and_conditions_files;
            const errorTerm ={
              status_code: 451,
              policyUrls: policyUrls};
            localStorage.setItem('terms_and_conditions', JSON.stringify(error?.response?.data?.is_terms_and_conditions_accepted));
            localStorage.setItem('tcUrls', JSON.stringify(errorTerm));
            window.location.replace(process.env.REACT_APP_HOMEPAGE +'term-condition');
          }
          // window.location.replace(process.env.REACT_APP_HOMEPAGE +'sign-up');
        }
        Promise.reject(error);
      }
    },

);
export default Http;
