/* eslint-disable max-len */
import React from 'react';
/**
 * Renders Component.
 * @return {InTakeN} Icon Component.
 */
export default function InTakeN() {
  return (
    <svg width="16" height="21" viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5 10C5.55228 10 6 9.55229 6 9C6 8.44772 5.55228 8 5 8C4.44772 8 4 8.44772 4 9C4 9.55229 4.44772 10 5 10Z" fill="#D29B22"/>
      <path d="M7 9C7 8.44772 7.44772 8 8 8H11C11.5523 8 12 8.44772 12 9C12 9.55229 11.5523 10 11 10H8C7.44772 10 7 9.55229 7 9Z" fill="#D29B22"/>
      <path d="M8 11C7.44772 11 7 11.4477 7 12C7 12.5523 7.44772 13 8 13H11C11.5523 13 12 12.5523 12 12C12 11.4477 11.5523 11 11 11H8Z" fill="#D29B22"/>
      <path d="M8 14C7.44772 14 7 14.4477 7 15C7 15.5523 7.44772 16 8 16H11C11.5523 16 12 15.5523 12 15C12 14.4477 11.5523 14 11 14H8Z" fill="#D29B22"/>
      <path d="M6 12C6 12.5523 5.55228 13 5 13C4.44772 13 4 12.5523 4 12C4 11.4477 4.44772 11 5 11C5.55228 11 6 11.4477 6 12Z" fill="#D29B22"/>
      <path d="M5 16C5.55228 16 6 15.5523 6 15C6 14.4477 5.55228 14 5 14C4.44772 14 4 14.4477 4 15C4 15.5523 4.44772 16 5 16Z" fill="#D29B22"/>
      <path d="M5 0.5C4.44772 0.5 4 0.947715 4 1.5H2C0.895431 1.5 0 2.39543 0 3.5V18.5C0 19.6046 0.895431 20.5 2 20.5H14C15.1046 20.5 16 19.6046 16 18.5V3.5C16 2.39543 15.1046 1.5 14 1.5H12C12 0.947715 11.5523 0.5 11 0.5H5ZM12 3.5H14V18.5H2V3.5H4V4.5C4 5.05228 4.44772 5.5 5 5.5H11C11.5523 5.5 12 5.05228 12 4.5V3.5ZM6 3.5V2.5H10V3.5H6Z" fill="#D29B22"/>
    </svg>


  );
}
