/* eslint-disable max-len */
import React from 'react';
/**
 * Renders Component.
 * @return {Lifestyle} Icon Component.
 */
export default function Lifestyle() {
  return (
    <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M5.76964 6.73497C8.72606 3.77855 13.4572 3.67856 16.5337 6.43498C19.6102 3.67856 24.3413 3.77855 27.2977 6.73497C30.3577 9.79488 30.3577 14.756 27.2977 17.8159L25.0026 20.111H29.593C30.3142 20.111 30.8989 20.6956 30.8989 21.4169C30.8989 22.1381 30.3142 22.7228 29.593 22.7228H22.3909L18.3805 26.7331C17.3605 27.7531 15.7068 27.7531 14.6869 26.7331L9.28036 21.3266L9.03347 21.9191C8.8307 22.4058 8.35521 22.7228 7.82802 22.7228H3.47503C2.7538 22.7228 2.16913 22.1381 2.16913 21.4169C2.16913 20.6956 2.7538 20.111 3.47503 20.111H6.95742L7.2831 19.3293L5.76964 17.8159C2.70973 14.756 2.70973 9.79488 5.76964 6.73497ZM10.3667 18.7193L16.5337 24.8863L20.243 21.177L18.9776 18.1403L16.805 22.0511C16.5535 22.5038 16.0582 22.7651 15.5426 22.7172C15.0269 22.6692 14.5883 22.3211 14.4245 21.8298L12.045 14.6914L10.3667 18.7193ZM22.2402 19.1798L20.3513 14.6463C20.1587 14.1842 19.7189 13.8728 19.2191 13.8447C18.7193 13.8166 18.2474 14.0767 18.0042 14.5144L15.9635 18.1876L13.4199 10.5567C13.2477 10.0401 12.7729 9.68455 12.2287 9.66466C11.6845 9.64478 11.185 9.96472 10.9756 10.4674L8.36947 16.7221L7.61646 15.9691C5.57652 13.9291 5.57652 10.6217 7.61646 8.58179C9.6564 6.54184 12.9638 6.54184 15.0037 8.58179L15.6103 9.18833C16.1203 9.69832 16.9471 9.69832 17.4571 9.18833L18.0636 8.58179C20.1036 6.54184 23.411 6.54184 25.4509 8.58179C27.4909 10.6217 27.4909 13.9291 25.4509 15.9691L22.2402 19.1798Z" fill="#355F92"/>
    </svg>

  );
}
