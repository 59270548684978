/* eslint-disable max-len */
import React from 'react';
/**
 * Renders Component.
 * @return {UserCheck} Icon Component.
 */
export default function Drop() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 0.5C10.3788 0.5 10.7251 0.714002 10.8945 1.05279L13.4734 6.2106L19.144 7.03541C19.5206 7.0902 19.8335 7.35402 19.9511 7.71599C20.0687 8.07796 19.9706 8.47531 19.6981 8.741L15.571 12.7649L16.4994 18.4385C16.5608 18.8135 16.4043 19.1908 16.0957 19.4124C15.787 19.6339 15.3794 19.6614 15.0438 19.4834L10 16.8071L4.95624 19.4834C4.62062 19.6614 4.21306 19.6339 3.9044 19.4124C3.59573 19.1908 3.4393 18.8135 3.50065 18.4385L4.42906 12.7649L0.301929 8.741C0.0294234 8.47531 -0.068637 8.07796 0.0489696 7.71599C0.166576 7.35402 0.479458 7.0902 0.856089 7.03541L6.5267 6.2106L9.1056 1.05279C9.27499 0.714002 9.62126 0.5 10 0.5ZM10 3.73607L8.08196 7.57221C7.93561 7.86491 7.65531 8.06748 7.33147 8.11459L3.14842 8.72303L6.19813 11.6965C6.43182 11.9243 6.53961 12.2519 6.4869 12.574L5.80004 16.7715L9.53131 14.7917C9.82444 14.6361 10.1756 14.6361 10.4687 14.7917L14.2 16.7715L13.5132 12.574C13.4604 12.2519 13.5682 11.9243 13.8019 11.6965L16.8516 8.72303L12.6686 8.11459C12.3448 8.06748 12.0645 7.86491 11.9181 7.57221L10 3.73607Z" fill="#72C4AA"/>
    </svg>

  );
}
