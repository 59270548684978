/* eslint-disable max-len */
import React from 'react';
/**
 * Renders Component.
 * @return {UsersIcon} Icon Component.
 */
export default function UsersIcon() {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.5 4C8.29086 4 6.5 5.79086 6.5 8C6.5 10.2091 8.29086 12 10.5 12C12.7091 12 14.5 10.2091 14.5 8C14.5 5.79086 12.7091 4 10.5 4ZM4.5 8C4.5 4.68629 7.18629 2 10.5 2C13.8137 2 16.5 4.68629 16.5 8C16.5 11.3137 13.8137 14 10.5 14C7.18629 14 4.5 11.3137 4.5 8ZM17.3284 3.75736C17.719 3.36683 18.3521 3.36683 18.7426 3.75736C21.0858 6.10051 21.0858 9.8995 18.7426 12.2426C18.3521 12.6332 17.719 12.6332 17.3284 12.2426C16.9379 11.8521 16.9379 11.219 17.3284 10.8284C18.8905 9.26633 18.8905 6.73367 17.3284 5.17157C16.9379 4.78105 16.9379 4.14788 17.3284 3.75736ZM18.0299 16.7575C18.1638 16.2217 18.7067 15.8959 19.2425 16.0299C20.5705 16.3618 21.411 17.2109 21.8944 18.1778C22.3622 19.1133 22.5 20.1571 22.5 21C22.5 21.5523 22.0523 22 21.5 22C20.9477 22 20.5 21.5523 20.5 21C20.5 20.3429 20.3878 19.6367 20.1056 19.0722C19.839 18.5391 19.4295 18.1382 18.7575 17.9701C18.2217 17.8362 17.8959 17.2933 18.0299 16.7575ZM7 18C5.74054 18 4.5 19.2135 4.5 21C4.5 21.5523 4.05228 22 3.5 22C2.94772 22 2.5 21.5523 2.5 21C2.5 18.3682 4.39347 16 7 16H14C16.6065 16 18.5 18.3682 18.5 21C18.5 21.5523 18.0523 22 17.5 22C16.9477 22 16.5 21.5523 16.5 21C16.5 19.2135 15.2595 18 14 18H7Z" fill="black"/>
    </svg>

  );
}
