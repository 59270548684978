/* eslint-disable max-len */
import React from 'react';
/**
 * Renders Component.
 * @return {TestResults} Icon Component.
 */
export default function TestResults() {
  return (
    <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 2C0 0.895432 0.89543 0 2 0H7C7.26522 0 7.51957 0.105357 7.70711 0.292893L9.41421 2H18C19.1046 2 20 2.89543 20 4V14C20 15.1046 19.1046 16 18 16H2C0.895432 16 0 15.1046 0 14V2ZM6.58579 2L2 2V14H18V4H9C8.73478 4 8.48043 3.89464 8.29289 3.70711L6.58579 2ZM13.7071 6.29289C14.0976 6.68342 14.0976 7.31658 13.7071 7.70711L9.70711 11.7071C9.51957 11.8946 9.26522 12 9 12C8.73478 12 8.48043 11.8946 8.29289 11.7071L6.29289 9.70711C5.90237 9.31658 5.90237 8.68342 6.29289 8.29289C6.68342 7.90237 7.31658 7.90237 7.70711 8.2929L9 9.58579L12.2929 6.29289C12.6834 5.90237 13.3166 5.90237 13.7071 6.29289Z" fill="#AA7910"/>
    </svg>
  );
}
