/* eslint-disable max-len */
import React from 'react';
/**
 * Renders Component.
 * @return {Supplement} Icon Component.
 */
export default function Supplement() {
  return (
    <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15 0V2H13V5C13.7956 5 14.5587 5.31607 15.1213 5.87868C15.6839 6.44129 16 7.20435 16 8V19C16 19.2652 15.8946 19.5196 15.7071 19.7071C15.5196 19.8946 15.2652 20 15 20H1C0.734784 20 0.48043 19.8946 0.292893 19.7071C0.105357 19.5196 0 19.2652 0 19V8C0 7.20435 0.316071 6.44129 0.87868 5.87868C1.44129 5.31607 2.20435 5 3 5V2H1V0H15ZM13 7H3C2.73478 7 2.48043 7.10536 2.29289 7.29289C2.10536 7.48043 2 7.73478 2 8V18H14V8C14 7.73478 13.8946 7.48043 13.7071 7.29289C13.5196 7.10536 13.2652 7 13 7ZM9 9V11H11V13H8.999L9 15H7L6.999 13H5V11H7V9H9ZM11 2H5V5H11V2Z" fill="#355F92"/>
    </svg>
  );
}
