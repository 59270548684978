/* eslint-disable max-len */
import React from 'react';
/**
 * Renders Component.
 * @return {Delay} Icon Component.
 */
export default function Delay() {
  return (
    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.00045 2.07711C5.09197 2.07711 1.92353 5.38332 1.92353 9.46173C1.92353 13.5401 5.09197 16.8463 9.00045 16.8463C12.9089 16.8463 16.0774 13.5401 16.0774 9.46173C16.0774 5.38332 12.9089 2.07711 9.00045 2.07711ZM0.154297 9.46173C0.154297 4.36371 4.11486 0.230957 9.00045 0.230957C13.886 0.230957 17.8466 4.36371 17.8466 9.46173C17.8466 14.5597 13.886 18.6925 9.00045 18.6925C4.11486 18.6925 0.154297 14.5597 0.154297 9.46173ZM9.00045 3.92326C9.48901 3.92326 9.88507 4.33654 9.88507 4.84634V9.07938L12.2798 11.5782C12.6253 11.9387 12.6253 12.5232 12.2798 12.8837C11.9344 13.2442 11.3742 13.2442 11.0288 12.8837L8.37494 10.1144C8.20904 9.94133 8.11584 9.70654 8.11584 9.46173V4.84634C8.11584 4.33654 8.51189 3.92326 9.00045 3.92326Z" fill="#278C71"/>
    </svg>
  );
}
