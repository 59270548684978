/* eslint-disable max-len */
import React from 'react';
/**
 * Renders Component.
 * @return {TestTube} Icon Component.
 */
export default function TestTube() {
  return (
    <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.8111 5.95589L15.1861 0.330887C15.0816 0.226007 14.9574 0.142791 14.8207 0.0860097C14.6839 0.0292286 14.5373 0 14.3893 0C14.2412 0 14.0946 0.0292286 13.9579 0.0860097C13.8211 0.142791 13.6969 0.226007 13.5924 0.330887L1.4049 12.5184C0.966197 12.94 0.616002 13.4449 0.374809 14.0035C0.133616 14.5621 0.00627025 15.1632 0.000225777 15.7716C-0.00581869 16.38 0.109559 16.9835 0.339607 17.5468C0.569654 18.1101 0.90975 18.6218 1.33999 19.052C1.77022 19.4823 2.28196 19.8224 2.84524 20.0524C3.40852 20.2825 4.01203 20.3979 4.62045 20.3918C5.22887 20.3858 5.82997 20.2584 6.38857 20.0172C6.94717 19.776 7.45204 19.4258 7.87365 18.9871L18.3736 8.48714L20.3705 7.82151C20.5515 7.75976 20.7142 7.65361 20.8436 7.51276C20.973 7.37191 21.065 7.20086 21.1111 7.01526C21.1526 6.82764 21.1469 6.63268 21.0945 6.44781C21.0422 6.26293 20.9448 6.09392 20.8111 5.95589ZM6.2799 17.3934C5.84478 17.8285 5.25463 18.073 4.63927 18.073C4.02392 18.073 3.43377 17.8285 2.99865 17.3934C2.56353 16.9583 2.31908 16.3681 2.31908 15.7528C2.31908 15.1374 2.56353 14.5473 2.99865 14.1121L5.37052 11.7403C5.61427 11.5809 6.92677 10.8403 9.00802 11.8809C9.66448 12.2138 10.3654 12.4506 11.0893 12.584L6.2799 17.3934ZM17.408 6.43401C17.242 6.48915 17.0911 6.58224 16.9674 6.70589L13.658 10.0153C13.4143 10.1746 12.1018 10.9153 10.0205 9.87464C9.36406 9.54172 8.66314 9.30492 7.93927 9.17151L14.3893 2.72151L17.933 6.26526L17.408 6.43401Z" fill="#4589AF"/>
    </svg>
  );
}
