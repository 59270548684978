/* eslint-disable max-len */
import React from 'react';
/**
 * Renders Component.
 * @return {Goals} Icon Component.
 */
export default function Goals() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2ZM0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10ZM14.6644 6.75259C15.0771 7.11951 15.1143 7.75158 14.7474 8.16436L9.41408 14.1644C9.22431 14.3779 8.9523 14.5 8.66667 14.5C8.38103 14.5 8.10903 14.3779 7.91926 14.1644L5.25259 11.1644C4.88567 10.7516 4.92285 10.1195 5.33564 9.75259C5.74842 9.38567 6.38049 9.42285 6.74741 9.83564L8.66667 11.9948L13.2526 6.83564C13.6195 6.42285 14.2516 6.38567 14.6644 6.75259Z" fill="#111A1C"/>
    </svg>

  );
}
