/* eslint-disable max-len */
import React from 'react';
/**
 * Renders Component.
 * @return {TextMessage} Icon Component.
 */
export default function TextMessage() {
  return (
    <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.41602 7.25016C2.41602 5.91547 3.49799 4.8335 4.83268 4.8335H24.166C25.5007 4.8335 26.5827 5.91547 26.5827 7.25016V20.5418C26.5827 21.8765 25.5007 22.9585 24.166 22.9585H18.6249L15.3538 26.2296C14.8819 26.7015 14.1168 26.7015 13.6449 26.2296L10.3738 22.9585H4.83268C3.49799 22.9585 2.41602 21.8765 2.41602 20.5418V7.25016ZM24.166 7.25016H4.83268V20.5418H10.8743C11.1948 20.5418 11.5022 20.6691 11.7288 20.8957L14.4993 23.6663L17.2699 20.8957C17.4965 20.6691 17.8039 20.5418 18.1243 20.5418H24.166V7.25016ZM7.24935 11.4793C7.24935 10.812 7.79034 10.271 8.45768 10.271H20.541C21.2084 10.271 21.7493 10.812 21.7493 11.4793C21.7493 12.1467 21.2084 12.6877 20.541 12.6877H8.45768C7.79034 12.6877 7.24935 12.1467 7.24935 11.4793ZM7.24935 16.3127C7.24935 15.6453 7.79034 15.1043 8.45768 15.1043H15.7077C16.375 15.1043 16.916 15.6453 16.916 16.3127C16.916 16.98 16.375 17.521 15.7077 17.521H8.45768C7.79034 17.521 7.24935 16.98 7.24935 16.3127Z" fill="#355F92"/>
    </svg>


  );
}
